<template>
  <div>
    <el-card v-loading="loading" class="box-card" shadow="never">
      <div slot="header">
        <span>Dashboard creadas mensualmente</span>
      </div>
      <el-form :inline="true">
        <el-form-item>
          <select-service v-model="serviceId"></select-service>
        </el-form-item>
        <el-form-item>
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="init"
          >
            Buscar
          </el-button>
        </el-form-item>
      </el-form>
      <el-row :gutter="20">
        <el-col :sm="24">
          <el-card v-loading="loading" class="box-card primary" shadow="never">
            <div slot="header">
              <span>Facturacion</span>
            </div>
            <el-table :data="dashboardInvoicing" stripe>
              <el-table-column
                property="year"
                label="Año"
                align="center"
              ></el-table-column>
              <el-table-column property="month_01" label="ENE" align="right">
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    placement="top"
                    :content="
                      $options.filters.formatToCurrency(scope.row.month_01)
                    "
                  >
                    <span>{{ scope.row.month_01 | formatToMoney }}</span>
                  </el-tooltip>
                  <br />
                  <el-tag
                    v-if="!scope.row.is_variant_01 && scope.row.variant_01 > 0"
                    type="success"
                  >
                    %
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tooltip
                    v-else
                    effect="dark"
                    placement="bottom"
                    :content="
                      $options.filters.formatToPercent(scope.row.variant_01)
                    "
                  >
                    <el-tag v-if="scope.row.variant_01 > 0" type="success">
                      {{ scope.row.variant_01 | formatToPercent }}
                      <i class="fad fa-arrow-alt-up"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_01 === 0">
                      {{ scope.row.variant_01 | formatToPercent }}
                      <i class="fad fa-equals"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_01 < 0" type="danger">
                      {{ scope.row.variant_01 | formatToPercent }}
                      <i class="fad fa-arrow-alt-down"></i>
                    </el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column property="month_02" label="FEB" align="right">
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    placement="top"
                    :content="
                      $options.filters.formatToCurrency(scope.row.month_02)
                    "
                  >
                    <span>{{ scope.row.month_02 | formatToMoney }}</span>
                  </el-tooltip>
                  <br />
                  <el-tag
                    v-if="!scope.row.is_variant_02 && scope.row.variant_02 > 0"
                    type="success"
                  >
                    %
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tooltip
                    v-else
                    effect="dark"
                    placement="bottom"
                    :content="
                      $options.filters.formatToPercent(scope.row.variant_02)
                    "
                  >
                    <el-tag v-if="scope.row.variant_02 > 0" type="success">
                      {{ scope.row.variant_02 | formatToPercent }}
                      <i class="fad fa-arrow-alt-up"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_02 === 0">
                      {{ scope.row.variant_02 | formatToPercent }}
                      <i class="fad fa-equals"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_02 < 0" type="danger">
                      {{ scope.row.variant_02 | formatToPercent }}
                      <i class="fad fa-arrow-alt-down"></i>
                    </el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column property="month_03" label="MAR" align="right">
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    placement="top"
                    :content="
                      $options.filters.formatToCurrency(scope.row.month_03)
                    "
                  >
                    <span>{{ scope.row.month_03 | formatToMoney }}</span>
                  </el-tooltip>
                  <br />
                  <el-tag
                    v-if="!scope.row.is_variant_03 && scope.row.variant_03 > 0"
                    type="success"
                  >
                    %
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tooltip
                    v-else
                    effect="dark"
                    placement="bottom"
                    :content="
                      $options.filters.formatToPercent(scope.row.variant_03)
                    "
                  >
                    <el-tag v-if="scope.row.variant_03 > 0" type="success">
                      {{ scope.row.variant_03 | formatToPercent }}
                      <i class="fad fa-arrow-alt-up"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_03 === 0">
                      {{ scope.row.variant_03 | formatToPercent }}
                      <i class="fad fa-equals"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_03 < 0" type="danger">
                      {{ scope.row.variant_03 | formatToPercent }}
                      <i class="fad fa-arrow-alt-down"></i>
                    </el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column property="month_04" label="ABR" align="right">
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    placement="top"
                    :content="
                      $options.filters.formatToCurrency(scope.row.month_04)
                    "
                  >
                    <span>{{ scope.row.month_04 | formatToMoney }}</span>
                  </el-tooltip>
                  <br />
                  <el-tag
                    v-if="!scope.row.is_variant_04 && scope.row.variant_04 > 0"
                    type="success"
                  >
                    %
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tooltip
                    v-else
                    effect="dark"
                    placement="bottom"
                    :content="
                      $options.filters.formatToPercent(scope.row.variant_04)
                    "
                  >
                    <el-tag v-if="scope.row.variant_04 > 0" type="success">
                      {{ scope.row.variant_04 | formatToPercent }}
                      <i class="fad fa-arrow-alt-up"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_04 === 0">
                      {{ scope.row.variant_04 | formatToPercent }}
                      <i class="fad fa-equals"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_04 < 0" type="danger">
                      {{ scope.row.variant_04 | formatToPercent }}
                      <i class="fad fa-arrow-alt-down"></i>
                    </el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column property="month_05" label="MAY" align="right">
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    placement="top"
                    :content="
                      $options.filters.formatToCurrency(scope.row.month_05)
                    "
                  >
                    <span>{{ scope.row.month_05 | formatToMoney }}</span>
                  </el-tooltip>
                  <br />
                  <el-tag
                    v-if="!scope.row.is_variant_05 && scope.row.variant_05 > 0"
                    type="success"
                  >
                    %
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tooltip
                    v-else
                    effect="dark"
                    placement="bottom"
                    :content="
                      $options.filters.formatToPercent(scope.row.variant_05)
                    "
                  >
                    <el-tag v-if="scope.row.variant_05 > 0" type="success">
                      {{ scope.row.variant_05 | formatToPercent }}
                      <i class="fad fa-arrow-alt-up"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_05 === 0">
                      {{ scope.row.variant_05 | formatToPercent }}
                      <i class="fad fa-equals"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_05 < 0" type="danger">
                      {{ scope.row.variant_05 | formatToPercent }}
                      <i class="fad fa-arrow-alt-down"></i>
                    </el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column property="month_06" label="JUN" align="right">
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    placement="top"
                    :content="
                      $options.filters.formatToCurrency(scope.row.month_06)
                    "
                  >
                    <span>{{ scope.row.month_06 | formatToMoney }}</span>
                  </el-tooltip>
                  <br />
                  <el-tag
                    v-if="!scope.row.is_variant_06 && scope.row.variant_06 > 0"
                    type="success"
                  >
                    %
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tooltip
                    v-else
                    effect="dark"
                    placement="bottom"
                    :content="
                      $options.filters.formatToPercent(scope.row.variant_06)
                    "
                  >
                    <el-tag v-if="scope.row.variant_06 > 0" type="success">
                      {{ scope.row.variant_06 | formatToPercent }}
                      <i class="fad fa-arrow-alt-up"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_06 === 0">
                      {{ scope.row.variant_06 | formatToPercent }}
                      <i class="fad fa-equals"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_06 < 0" type="danger">
                      {{ scope.row.variant_06 | formatToPercent }}
                      <i class="fad fa-arrow-alt-down"></i>
                    </el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column property="month_07" label="JUL" align="right">
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    placement="top"
                    :content="
                      $options.filters.formatToCurrency(scope.row.month_07)
                    "
                  >
                    <span>{{ scope.row.month_07 | formatToMoney }}</span>
                  </el-tooltip>
                  <br />
                  <el-tag
                    v-if="!scope.row.is_variant_07 && scope.row.variant_07 > 0"
                    type="success"
                  >
                    %
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tooltip
                    v-else
                    effect="dark"
                    placement="bottom"
                    :content="
                      $options.filters.formatToPercent(scope.row.variant_07)
                    "
                  >
                    <el-tag v-if="scope.row.variant_07 > 0" type="success">
                      {{ scope.row.variant_07 | formatToPercent }}
                      <i class="fad fa-arrow-alt-up"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_07 === 0">
                      {{ scope.row.variant_07 | formatToPercent }}
                      <i class="fad fa-equals"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_07 < 0" type="danger">
                      {{ scope.row.variant_07 | formatToPercent }}
                      <i class="fad fa-arrow-alt-down"></i>
                    </el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column property="month_08" label="AGO" align="right">
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    placement="top"
                    :content="
                      $options.filters.formatToCurrency(scope.row.month_08)
                    "
                  >
                    <span>{{ scope.row.month_08 | formatToMoney }}</span>
                  </el-tooltip>
                  <br />
                  <el-tag
                    v-if="!scope.row.is_variant_08 && scope.row.variant_08 > 0"
                    type="success"
                  >
                    %
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tooltip
                    v-else
                    effect="dark"
                    placement="bottom"
                    :content="
                      $options.filters.formatToPercent(scope.row.variant_08)
                    "
                  >
                    <el-tag v-if="scope.row.variant_08 > 0" type="success">
                      {{ scope.row.variant_08 | formatToPercent }}
                      <i class="fad fa-arrow-alt-up"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_08 === 0">
                      {{ scope.row.variant_08 | formatToPercent }}
                      <i class="fad fa-equals"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_08 < 0" type="danger">
                      {{ scope.row.variant_08 | formatToPercent }}
                      <i class="fad fa-arrow-alt-down"></i>
                    </el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column property="month_09" label="SEP" align="right">
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    placement="top"
                    :content="
                      $options.filters.formatToCurrency(scope.row.month_09)
                    "
                  >
                    <span>{{ scope.row.month_09 | formatToMoney }}</span>
                  </el-tooltip>
                  <br />
                  <el-tag
                    v-if="!scope.row.is_variant_09 && scope.row.variant_09 > 0"
                    type="success"
                  >
                    %
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tooltip
                    v-else
                    effect="dark"
                    placement="bottom"
                    :content="
                      $options.filters.formatToPercent(scope.row.variant_09)
                    "
                  >
                    <el-tag v-if="scope.row.variant_09 > 0" type="success">
                      {{ scope.row.variant_09 | formatToPercent }}
                      <i class="fad fa-arrow-alt-up"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_09 === 0">
                      {{ scope.row.variant_09 | formatToPercent }}
                      <i class="fad fa-equals"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_09 < 0" type="danger">
                      {{ scope.row.variant_09 | formatToPercent }}
                      <i class="fad fa-arrow-alt-down"></i>
                    </el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column property="month_10" label="OCT" align="right">
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    placement="top"
                    :content="
                      $options.filters.formatToCurrency(scope.row.month_10)
                    "
                  >
                    <span>{{ scope.row.month_10 | formatToMoney }}</span>
                  </el-tooltip>
                  <br />
                  <el-tag
                    v-if="!scope.row.is_variant_10 && scope.row.variant_10 > 0"
                    type="success"
                  >
                    %
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tooltip
                    v-else
                    effect="dark"
                    placement="bottom"
                    :content="
                      $options.filters.formatToPercent(scope.row.variant_10)
                    "
                  >
                    <el-tag v-if="scope.row.variant_10 > 0" type="success">
                      {{ scope.row.variant_10 | formatToPercent }}
                      <i class="fad fa-arrow-alt-up"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_10 === 0">
                      {{ scope.row.variant_10 | formatToPercent }}
                      <i class="fad fa-equals"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_10 < 0" type="danger">
                      {{ scope.row.variant_10 | formatToPercent }}
                      <i class="fad fa-arrow-alt-down"></i>
                    </el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column property="month_11" label="NOV" align="right">
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    placement="top"
                    :content="
                      $options.filters.formatToCurrency(scope.row.month_11)
                    "
                  >
                    <span>{{ scope.row.month_11 | formatToMoney }}</span>
                  </el-tooltip>
                  <br />
                  <el-tag
                    v-if="!scope.row.is_variant_11 && scope.row.variant_11 > 0"
                    type="success"
                  >
                    %
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tooltip
                    v-else
                    effect="dark"
                    placement="bottom"
                    :content="
                      $options.filters.formatToPercent(scope.row.variant_11)
                    "
                  >
                    <el-tag v-if="scope.row.variant_11 > 0" type="success">
                      {{ scope.row.variant_11 | formatToPercent }}
                      <i class="fad fa-arrow-alt-up"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_11 === 0">
                      {{ scope.row.variant_11 | formatToPercent }}
                      <i class="fad fa-equals"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_11 < 0" type="danger">
                      {{ scope.row.variant_11 | formatToPercent }}
                      <i class="fad fa-arrow-alt-down"></i>
                    </el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column property="month_12" label="DIC" align="right">
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    placement="top"
                    :content="
                      $options.filters.formatToCurrency(scope.row.month_12)
                    "
                  >
                    <span>{{ scope.row.month_12 | formatToMoney }}</span>
                  </el-tooltip>
                  <br />
                  <el-tag
                    v-if="!scope.row.is_variant_12 && scope.row.variant_12 > 0"
                    type="success"
                  >
                    %
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tooltip
                    v-else
                    effect="dark"
                    placement="bottom"
                    :content="
                      $options.filters.formatToPercent(scope.row.variant_12)
                    "
                  >
                    <el-tag v-if="scope.row.variant_12 > 0" type="success">
                      {{ scope.row.variant_12 | formatToPercent }}
                      <i class="fad fa-arrow-alt-up"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_12 === 0">
                      {{ scope.row.variant_12 | formatToPercent }}
                      <i class="fad fa-equals"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_12 < 0" type="danger">
                      {{ scope.row.variant_12 | formatToPercent }}
                      <i class="fad fa-arrow-alt-down"></i>
                    </el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column property="total" label="TOTAL" align="right">
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    placement="top"
                    :content="
                      $options.filters.formatToCurrency(scope.row.total)
                    "
                  >
                    <span>{{ scope.row.total | formatToMoney }}</span>
                  </el-tooltip>
                  <br />
                  <el-tag
                    v-if="
                      !scope.row.is_variant_total && scope.row.variant_total > 0
                    "
                    type="success"
                  >
                    %
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tooltip
                    v-else
                    effect="dark"
                    placement="bottom"
                    :content="
                      $options.filters.formatToPercent(scope.row.variant_total)
                    "
                  >
                    <el-tag v-if="scope.row.variant_total > 0" type="success">
                      {{ scope.row.variant_total | formatToPercent }}
                      <i class="fad fa-arrow-alt-up"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_total === 0">
                      {{ scope.row.variant_total | formatToPercent }}
                      <i class="fad fa-equals"></i>
                    </el-tag>
                    <el-tag v-if="scope.row.variant_total < 0" type="danger">
                      {{ scope.row.variant_total | formatToPercent }}
                      <i class="fad fa-arrow-alt-down"></i>
                    </el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :sm="24">
          <el-card v-loading="loading" class="box-card info" shadow="never">
            <div slot="header">
              <span>Cuentas creadas mensualmente</span>
            </div>
            <el-table :data="dashboardCreated" stripe>
              <el-table-column
                property="year"
                label="Año"
                align="center"
              ></el-table-column>
              <el-table-column property="month_01" label="ENE" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.month_01 }}</span>
                  <br />
                  <el-tag v-if="scope.row.variant_01 > 0" type="success">
                    {{ scope.row.variant_01 | formatToPercent }}
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_01 === 0">
                    {{ scope.row.variant_01 | formatToPercent }}
                    <i class="fad fa-equals"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_01 < 0" type="danger">
                    {{ scope.row.variant_01 | formatToPercent }}
                    <i class="fad fa-arrow-alt-down"></i>
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="month_02" label="FEB" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.month_02 }}</span>
                  <br />
                  <el-tag v-if="scope.row.variant_02 > 0" type="success">
                    {{ scope.row.variant_02 | formatToPercent }}
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_02 === 0">
                    {{ scope.row.variant_02 | formatToPercent }}
                    <i class="fad fa-equals"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_02 < 0" type="danger">
                    {{ scope.row.variant_02 | formatToPercent }}
                    <i class="fad fa-arrow-alt-down"></i>
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="month_03" label="MAR" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.month_03 }}</span>
                  <br />
                  <el-tag v-if="scope.row.variant_03 > 0" type="success">
                    {{ scope.row.variant_03 | formatToPercent }}
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_03 === 0">
                    {{ scope.row.variant_03 | formatToPercent }}
                    <i class="fad fa-equals"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_03 < 0" type="danger">
                    {{ scope.row.variant_03 | formatToPercent }}
                    <i class="fad fa-arrow-alt-down"></i>
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="month_04" label="ABR" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.month_04 }}</span>
                  <br />
                  <el-tag v-if="scope.row.variant_04 > 0" type="success">
                    {{ scope.row.variant_04 | formatToPercent }}
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_04 === 0">
                    {{ scope.row.variant_04 | formatToPercent }}
                    <i class="fad fa-equals"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_04 < 0" type="danger">
                    {{ scope.row.variant_04 | formatToPercent }}
                    <i class="fad fa-arrow-alt-down"></i>
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="month_05" label="MAY" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.month_05 }}</span>
                  <br />
                  <el-tag v-if="scope.row.variant_05 > 0" type="success">
                    {{ scope.row.variant_05 | formatToPercent }}
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_05 === 0">
                    {{ scope.row.variant_05 | formatToPercent }}
                    <i class="fad fa-equals"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_05 < 0" type="danger">
                    {{ scope.row.variant_05 | formatToPercent }}
                    <i class="fad fa-arrow-alt-down"></i>
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="month_06" label="JUN" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.month_06 }}</span>
                  <br />
                  <el-tag v-if="scope.row.variant_06 > 0" type="success">
                    {{ scope.row.variant_06 | formatToPercent }}
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_06 === 0">
                    {{ scope.row.variant_06 | formatToPercent }}
                    <i class="fad fa-equals"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_06 < 0" type="danger">
                    {{ scope.row.variant_06 | formatToPercent }}
                    <i class="fad fa-arrow-alt-down"></i>
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="month_07" label="JUL" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.month_07 }}</span>
                  <br />
                  <el-tag v-if="scope.row.variant_07 > 0" type="success">
                    {{ scope.row.variant_07 | formatToPercent }}
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_07 === 0">
                    {{ scope.row.variant_07 | formatToPercent }}
                    <i class="fad fa-equals"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_07 < 0" type="danger">
                    {{ scope.row.variant_07 | formatToPercent }}
                    <i class="fad fa-arrow-alt-down"></i>
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="month_08" label="AGO" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.month_08 }}</span>
                  <br />
                  <el-tag v-if="scope.row.variant_08 > 0" type="success">
                    {{ scope.row.variant_08 | formatToPercent }}
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_08 === 0">
                    {{ scope.row.variant_08 | formatToPercent }}
                    <i class="fad fa-equals"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_08 < 0" type="danger">
                    {{ scope.row.variant_08 | formatToPercent }}
                    <i class="fad fa-arrow-alt-down"></i>
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="month_09" label="SEP" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.month_09 }}</span>
                  <br />
                  <el-tag v-if="scope.row.variant_09 > 0" type="success">
                    {{ scope.row.variant_09 | formatToPercent }}
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_09 === 0">
                    {{ scope.row.variant_09 | formatToPercent }}
                    <i class="fad fa-equals"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_09 < 0" type="danger">
                    {{ scope.row.variant_09 | formatToPercent }}
                    <i class="fad fa-arrow-alt-down"></i>
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="month_10" label="OCT" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.month_10 }}</span>
                  <br />
                  <el-tag v-if="scope.row.variant_10 > 0" type="success">
                    {{ scope.row.variant_10 | formatToPercent }}
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_10 === 0">
                    {{ scope.row.variant_10 | formatToPercent }}
                    <i class="fad fa-equals"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_10 < 0" type="danger">
                    {{ scope.row.variant_10 | formatToPercent }}
                    <i class="fad fa-arrow-alt-down"></i>
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="month_11" label="NOV" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.month_11 }}</span>
                  <br />
                  <el-tag v-if="scope.row.variant_11 > 0" type="success">
                    {{ scope.row.variant_11 | formatToPercent }}
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_11 === 0">
                    {{ scope.row.variant_11 | formatToPercent }}
                    <i class="fad fa-equals"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_11 < 0" type="danger">
                    {{ scope.row.variant_11 | formatToPercent }}
                    <i class="fad fa-arrow-alt-down"></i>
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="month_12" label="DIC" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.month_12 }}</span>
                  <br />
                  <el-tag v-if="scope.row.variant_12 > 0" type="success">
                    {{ scope.row.variant_12 | formatToPercent }}
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_12 === 0">
                    {{ scope.row.variant_12 | formatToPercent }}
                    <i class="fad fa-equals"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_12 < 0" type="danger">
                    {{ scope.row.variant_12 | formatToPercent }}
                    <i class="fad fa-arrow-alt-down"></i>
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="total" label="TOTAL" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.total }}</span>
                  <br />
                  <el-tag v-if="scope.row.variant_total > 0" type="success">
                    {{ scope.row.variant_total | formatToPercent }}
                    <i class="fad fa-arrow-alt-up"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_total === 0">
                    {{ scope.row.variant_total | formatToPercent }}
                    <i class="fad fa-equals"></i>
                  </el-tag>
                  <el-tag v-if="scope.row.variant_total < 0" type="danger">
                    {{ scope.row.variant_total | formatToPercent }}
                    <i class="fad fa-arrow-alt-down"></i>
                  </el-tag>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 10px">
        <el-col :sm="12">
          <el-card
            v-loading="loading"
            class="box-card secondary"
            shadow="never"
          >
            <div slot="header">
              <span>Cuentas por estados</span>
            </div>
            <el-table :data="dashboardStatus" stripe>
              <el-table-column
                property="status"
                label="Estado"
              ></el-table-column>
              <el-table-column
                property="quantity"
                label="Cantidad"
                align="right"
              ></el-table-column>
            </el-table>
          </el-card>
        </el-col>
        <el-col :sm="12">
          <el-card v-loading="loading" class="box-card success" shadow="never">
            <div slot="header">
              <span>Cuentas por estados mensual</span>
            </div>
            <el-table :data="dashboardStatusMonth" stripe>
              <el-table-column
                property="date"
                label="Año - Mes"
                align="center"
              ></el-table-column>
              <el-table-column
                property="enabled"
                label="Activas-Lectura"
                align="right"
              ></el-table-column>
              <el-table-column
                property="test"
                label="Pruebas"
                align="right"
              ></el-table-column>
              <el-table-column
                property="suspended"
                label="Suspendidas"
                align="right"
              ></el-table-column>
              <el-table-column
                property="canceled"
                label="Canceladas"
                align="right"
              ></el-table-column>
              <el-table-column
                property="pruebaFinalizada"
                label="PruebaFinalizada"
                align="right"
              ></el-table-column>
              <el-table-column
                property="created"
                label="Creadas"
                align="right"
              ></el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 10px">
        <el-col :sm="24">
          <el-card
            v-loading="loadingLastLogin"
            class="box-card warning"
            shadow="never"
          >
            <div slot="header">
              <span>Alerta de uso</span>
              <el-button
                size="mini"
                type="info"
                style="float: right"
                @click="exportLastLogin"
              >
                <i class="fad fa-download"></i>&nbsp;{{ $t('export') }}
              </el-button>
            </div>
            <el-table :data="dashboardLastLogin" stripe>
              <el-table-column
                property="status"
                label="Estado"
                align="center"
              ></el-table-column>
              <el-table-column property="day_15" label="15 Dias" align="right">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click.prevent="seeDialog(scope.row.status, 'day_15')"
                  >
                    {{ scope.row.day_15 }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column property="month_01" label="1 Mes" align="right">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click.prevent="seeDialog(scope.row.status, 'month_01')"
                  >
                    {{ scope.row.month_01 }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                property="month_02"
                label="2 Meses"
                align="right"
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click.prevent="seeDialog(scope.row.status, 'month_02')"
                  >
                    {{ scope.row.month_02 }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                property="month_03"
                label="3 Meses"
                align="right"
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click.prevent="seeDialog(scope.row.status, 'month_03')"
                  >
                    {{ scope.row.month_03 }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                property="month_04"
                label="4 Meses"
                align="right"
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click.prevent="seeDialog(scope.row.status, 'month_04')"
                  >
                    {{ scope.row.month_04 }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                property="month_05"
                label="5 Meses"
                align="right"
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click.prevent="seeDialog(scope.row.status, 'month_05')"
                  >
                    {{ scope.row.month_05 }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                property="month_06"
                label="6 Meses"
                align="right"
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click.prevent="seeDialog(scope.row.status, 'month_06')"
                  >
                    {{ scope.row.month_06 }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                property="month_more"
                label="Mas de 6 meses"
                align="right"
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click.prevent="seeDialog(scope.row.status, 'month_more')"
                  >
                    {{ scope.row.month_more }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column property="total" label="Total" align="right">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click.prevent="seeDialog(scope.row.status, '')"
                  >
                    {{ scope.row.total }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
    <el-dialog title="Alerta de uso" :visible.sync="dialog">
      <el-table
        v-loading="loadingDialog"
        :data="dashboardLastLoginDialog"
        stripe
      >
        <el-table-column
          property="company_name"
          label="Nombre"
        ></el-table-column>
        <el-table-column
          property="company_phone"
          label="Telefono"
        ></el-table-column>
        <el-table-column
          property="service_name"
          label="Servicio"
        ></el-table-column>
        <el-table-column property="plan_name" label="Plan"></el-table-column>
        <el-table-column
          property="company_service_price"
          label="Precio"
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.company_service_price | formatToCurrency }}
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer">
        <el-button size="mini" @click="dialog = false">Cerrar</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'Home',
  data() {
    return {
      serviceId: '',
      dashboardCreated: [],
      dashboardStatus: [],
      dashboardStatusMonth: [],
      dashboardLastLogin: [],
      dashboardLastLoginDetail: [],
      dashboardInvoicing: [],
      loading: false,
      loadingLastLogin: false,
      loadingInvoicing: false,

      // *Dialog
      dialog: false,
      loadingDialog: false,
      dashboardLastLoginDialog: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.get()
      this.getLastLogin()
      this.getInvoicing()
    },
    get() {
      const _this = this
      _this.loading = true
      _this.$http
        .get(`companies/report/${_this.serviceId ? _this.serviceId : '0'}`)
        .then(
          response => {
            _this.loading = false
            _this.dashboardCreated = response.body[0]
            _this.dashboardStatusMonth = response.body[1]
            _this.dashboardStatus = response.body[2]

            _this.variat()
          },
          response => {
            _this.loading = false
          },
        )
    },
    getLastLogin() {
      const _this = this
      _this.loadingLastLogin = true
      _this.$http
        .get(
          `companies/report/LastLogin/${
            _this.serviceId ? _this.serviceId : '0'
          }`,
        )
        .then(
          response => {
            _this.loadingLastLogin = false
            _this.dashboardLastLogin = response.body[0]
            _this.dashboardLastLoginDetail = response.body[1]
          },
          response => {
            _this.loadingLastLogin = false
          },
        )
    },
    getInvoicing() {
      const _this = this
      _this.loadingInvoicing = true
      _this.$http
        .get(`bills/report/${_this.serviceId ? _this.serviceId : '0'}`)
        .then(
          response => {
            _this.loadingInvoicing = false
            _this.dashboardInvoicing = response.body

            _this.variatInvoicing()
          },
          response => {
            _this.loadingInvoicing = false
          },
        )
    },
    seeDialog(status, type) {
      const _this = this
      _this.loadingDialog = true
      _this.dialog = true
      _this.dashboardLastLoginDialog = _this.dashboardLastLoginDetail.filter(
        i => i.company_service_status === status,
      )
      if (type) {
        _this.dashboardLastLoginDialog = _this.dashboardLastLoginDialog.filter(
          i => i.type === type,
        )
      }
      _this.loadingDialog = false
    },
    variat() {
      const _this = this
      if (_this.dashboardCreated.length > 1) {
        _this.loading = true
        for (
          let index = 0;
          index < _this.dashboardCreated.length - 1;
          index++
        ) {
          if (index !== _this.dashboardCreated.length - 2) {
            _this.dashboardCreated[index].variant_01 =
              (_this.dashboardCreated[index + 1].month_01 !== 0
                ? _this.dashboardCreated[index].month_01 /
                    _this.dashboardCreated[index + 1].month_01 -
                  1
                : _this.dashboardCreated[index].month_01) * 100
            _this.dashboardCreated[index].variant_02 =
              (_this.dashboardCreated[index + 1].month_02 !== 0
                ? _this.dashboardCreated[index].month_02 /
                    _this.dashboardCreated[index + 1].month_02 -
                  1
                : _this.dashboardCreated[index].month_02) * 100
            _this.dashboardCreated[index].variant_03 =
              (_this.dashboardCreated[index + 1].month_03 !== 0
                ? _this.dashboardCreated[index].month_03 /
                    _this.dashboardCreated[index + 1].month_03 -
                  1
                : _this.dashboardCreated[index].month_03) * 100
            _this.dashboardCreated[index].variant_04 =
              (_this.dashboardCreated[index + 1].month_04 !== 0
                ? _this.dashboardCreated[index].month_04 /
                    _this.dashboardCreated[index + 1].month_04 -
                  1
                : _this.dashboardCreated[index].month_04) * 100
            _this.dashboardCreated[index].variant_05 =
              (_this.dashboardCreated[index + 1].month_05 !== 0
                ? _this.dashboardCreated[index].month_05 /
                    _this.dashboardCreated[index + 1].month_05 -
                  1
                : _this.dashboardCreated[index].month_05) * 100
            _this.dashboardCreated[index].variant_06 =
              (_this.dashboardCreated[index + 1].month_06 !== 0
                ? _this.dashboardCreated[index].month_06 /
                    _this.dashboardCreated[index + 1].month_06 -
                  1
                : _this.dashboardCreated[index].month_06) * 100
            _this.dashboardCreated[index].variant_07 =
              (_this.dashboardCreated[index + 1].month_07 !== 0
                ? _this.dashboardCreated[index].month_07 /
                    _this.dashboardCreated[index + 1].month_07 -
                  1
                : _this.dashboardCreated[index].month_07) * 100
            _this.dashboardCreated[index].variant_08 =
              (_this.dashboardCreated[index + 1].month_08 !== 0
                ? _this.dashboardCreated[index].month_08 /
                    _this.dashboardCreated[index + 1].month_08 -
                  1
                : _this.dashboardCreated[index].month_08) * 100
            _this.dashboardCreated[index].variant_09 =
              (_this.dashboardCreated[index + 1].month_09 !== 0
                ? _this.dashboardCreated[index].month_09 /
                    _this.dashboardCreated[index + 1].month_09 -
                  1
                : _this.dashboardCreated[index].month_09) * 100
            _this.dashboardCreated[index].variant_10 =
              (_this.dashboardCreated[index + 1].month_10 !== 0
                ? _this.dashboardCreated[index].month_10 /
                    _this.dashboardCreated[index + 1].month_10 -
                  1
                : _this.dashboardCreated[index].month_10) * 100
            _this.dashboardCreated[index].variant_11 =
              (_this.dashboardCreated[index + 1].month_11 !== 0
                ? _this.dashboardCreated[index].month_11 /
                    _this.dashboardCreated[index + 1].month_11 -
                  1
                : _this.dashboardCreated[index].month_11) * 100
            _this.dashboardCreated[index].variant_12 =
              (_this.dashboardCreated[index + 1].month_12 !== 0
                ? _this.dashboardCreated[index].month_12 /
                    _this.dashboardCreated[index + 1].month_12 -
                  1
                : _this.dashboardCreated[index].month_12) * 100
            _this.dashboardCreated[index].variant_total =
              (_this.dashboardCreated[index + 1].total !== 0
                ? _this.dashboardCreated[index].total /
                    _this.dashboardCreated[index + 1].total -
                  1
                : _this.dashboardCreated[index].total) * 100
          }
        }
        _this.loading = false
      }
    },
    variatInvoicing() {
      const _this = this
      if (_this.dashboardInvoicing.length > 1) {
        _this.loadingInvoicing = true
        for (
          let index = 0;
          index < _this.dashboardInvoicing.length - 1;
          index++
        ) {
          if (index !== _this.dashboardInvoicing.length - 2) {
            _this.dashboardInvoicing[index].is_variant_01 =
              _this.dashboardInvoicing[index + 1].month_01 !== 0
            _this.dashboardInvoicing[index].is_variant_02 =
              _this.dashboardInvoicing[index + 1].month_02 !== 0
            _this.dashboardInvoicing[index].is_variant_03 =
              _this.dashboardInvoicing[index + 1].month_03 !== 0
            _this.dashboardInvoicing[index].is_variant_04 =
              _this.dashboardInvoicing[index + 1].month_04 !== 0
            _this.dashboardInvoicing[index].is_variant_05 =
              _this.dashboardInvoicing[index + 1].month_05 !== 0
            _this.dashboardInvoicing[index].is_variant_06 =
              _this.dashboardInvoicing[index + 1].month_06 !== 0
            _this.dashboardInvoicing[index].is_variant_07 =
              _this.dashboardInvoicing[index + 1].month_07 !== 0
            _this.dashboardInvoicing[index].is_variant_08 =
              _this.dashboardInvoicing[index + 1].month_08 !== 0
            _this.dashboardInvoicing[index].is_variant_09 =
              _this.dashboardInvoicing[index + 1].month_09 !== 0
            _this.dashboardInvoicing[index].is_variant_10 =
              _this.dashboardInvoicing[index + 1].month_10 !== 0
            _this.dashboardInvoicing[index].is_variant_11 =
              _this.dashboardInvoicing[index + 1].month_11 !== 0
            _this.dashboardInvoicing[index].is_variant_12 =
              _this.dashboardInvoicing[index + 1].month_12 !== 0
            _this.dashboardInvoicing[index].is_variant_total =
              _this.dashboardInvoicing[index + 1].total !== 0

            _this.dashboardInvoicing[index].variant_01 =
              (_this.dashboardInvoicing[index + 1].month_01 !== 0
                ? _this.dashboardInvoicing[index].month_01 /
                    _this.dashboardInvoicing[index + 1].month_01 -
                  1
                : _this.dashboardInvoicing[index].month_01) * 100
            _this.dashboardInvoicing[index].variant_02 =
              (_this.dashboardInvoicing[index + 1].month_02 !== 0
                ? _this.dashboardInvoicing[index].month_02 /
                    _this.dashboardInvoicing[index + 1].month_02 -
                  1
                : _this.dashboardInvoicing[index].month_02) * 100
            _this.dashboardInvoicing[index].variant_03 =
              (_this.dashboardInvoicing[index + 1].month_03 !== 0
                ? _this.dashboardInvoicing[index].month_03 /
                    _this.dashboardInvoicing[index + 1].month_03 -
                  1
                : _this.dashboardInvoicing[index].month_03) * 100
            _this.dashboardInvoicing[index].variant_04 =
              (_this.dashboardInvoicing[index + 1].month_04 !== 0
                ? _this.dashboardInvoicing[index].month_04 /
                    _this.dashboardInvoicing[index + 1].month_04 -
                  1
                : _this.dashboardInvoicing[index].month_04) * 100
            _this.dashboardInvoicing[index].variant_05 =
              (_this.dashboardInvoicing[index + 1].month_05 !== 0
                ? _this.dashboardInvoicing[index].month_05 /
                    _this.dashboardInvoicing[index + 1].month_05 -
                  1
                : _this.dashboardInvoicing[index].month_05) * 100
            _this.dashboardInvoicing[index].variant_06 =
              (_this.dashboardInvoicing[index + 1].month_06 !== 0
                ? _this.dashboardInvoicing[index].month_06 /
                    _this.dashboardInvoicing[index + 1].month_06 -
                  1
                : _this.dashboardInvoicing[index].month_06) * 100
            _this.dashboardInvoicing[index].variant_07 =
              (_this.dashboardInvoicing[index + 1].month_07 !== 0
                ? _this.dashboardInvoicing[index].month_07 /
                    _this.dashboardInvoicing[index + 1].month_07 -
                  1
                : _this.dashboardInvoicing[index].month_07) * 100
            _this.dashboardInvoicing[index].variant_08 =
              (_this.dashboardInvoicing[index + 1].month_08 !== 0
                ? _this.dashboardInvoicing[index].month_08 /
                    _this.dashboardInvoicing[index + 1].month_08 -
                  1
                : _this.dashboardInvoicing[index].month_08) * 100
            _this.dashboardInvoicing[index].variant_09 =
              (_this.dashboardInvoicing[index + 1].month_09 !== 0
                ? _this.dashboardInvoicing[index].month_09 /
                    _this.dashboardInvoicing[index + 1].month_09 -
                  1
                : _this.dashboardInvoicing[index].month_09) * 100
            _this.dashboardInvoicing[index].variant_10 =
              (_this.dashboardInvoicing[index + 1].month_10 !== 0
                ? _this.dashboardInvoicing[index].month_10 /
                    _this.dashboardInvoicing[index + 1].month_10 -
                  1
                : _this.dashboardInvoicing[index].month_10) * 100
            _this.dashboardInvoicing[index].variant_11 =
              (_this.dashboardInvoicing[index + 1].month_11 !== 0
                ? _this.dashboardInvoicing[index].month_11 /
                    _this.dashboardInvoicing[index + 1].month_11 -
                  1
                : _this.dashboardInvoicing[index].month_11) * 100
            _this.dashboardInvoicing[index].variant_12 =
              (_this.dashboardInvoicing[index + 1].month_12 !== 0
                ? _this.dashboardInvoicing[index].month_12 /
                    _this.dashboardInvoicing[index + 1].month_12 -
                  1
                : _this.dashboardInvoicing[index].month_12) * 100
            _this.dashboardInvoicing[index].variant_total =
              (_this.dashboardInvoicing[index + 1].total !== 0
                ? _this.dashboardInvoicing[index].total /
                    _this.dashboardInvoicing[index + 1].total -
                  1
                : _this.dashboardInvoicing[index].total) * 100
          }
        }
        _this.loadingInvoicing = false
      }
    },
    exportLastLogin() {
      const _this = this
      _this.loadingLastLogin = true
      _this.$http
        .get(
          `companies/report/lastLogin/export/${
            _this.serviceId ? _this.serviceId : '0'
          }`,
        )
        .then(
          response => {
            window.open(response.body)
            _this.loadingLastLogin = false
          },
          response => {
            _this.loadingLastLogin = false
          },
        )
    },
  },
}
</script>
